/*======= Responsive ========*/
@media screen and (min-width: 2500px) {
  .main-header .navbar-nav li a {
    font-size: 2.3rem !important;
    padding: 0 1.5rem !important;
  }
  .navbar-brand img {
    width: 136px;
  }
  .main-header {
    padding: 1.2rem 0 !important;
  }
  .main-wrapper {
    margin-top: 7.3rem;
  }
  .about-banner .about-content h3 {
    font-size: 7rem !important;
  }
  .about-banner .about-content h5 {
    font-size: 6rem !important;
  }
  html body * p {
    font-size: 2rem !important;
  }
  .section_title h2 {
    font-size: 4rem !important;
  }
}
@media screen and (min-width:2000px){
  .hwoitwork_banner{
    .how-work-header{
      top: 38%;
    }
  }
}

@media screen and (min-width: 1700px) {
  .section_one {
    .container-fluid {
      margin-left: auto;
      margin-right: 0;
      max-width: 90%;
    }
  }
  body * p {
    font-size: 1.378rem;
  }
  .container-fluid {
    max-width: 1400px;
    padding: 0;
  }
  .section-three {
    .casestudy-block {
      max-width: 84%;
      margin-left: auto;
    }
  }
  .section-three .study-card {
    height: 550px;
    &:before {
      /*width: 190px;*/
    }
    h3 {
      max-width: 400px;
      font-size: 2rem;
    }
    p {
      /*font-size: 2.3rem !important;
            line-height: 1.1;*/
    }
    .country {
      font-size: 1.4rem !important;
    }
    .date {
      font-size: 1.5rem !important;
      &:before {
        bottom: 8px;
      }
    }
    .count {
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 2rem;
    }
  }
  .main-header .navbar-nav li a {
    font-size: 1.25rem;
  }
  .navbar-brand img {
    width: 136px;
  }
  .about-banner .about-content {
    top: 35%;
    h3 {
      font-size: 4.5rem;
    }
    h5 {
      font-size: 4rem;
    }
  }
  .Know_us h2,
  .know_us h2 {
    /*font-size: 4.5rem;*/
    line-height: 1;
  }
  /* .Know_us h2 {
        margin-bottom: 5rem;
    }*/
  .know_us ul li a {
    /*font-size: 3.7rem;*/
  }
  .gray-box {
    width: 388px;
  }
  .Know_us .position-relative {
    height: 385px;
  }
  .Know_us .media .media-body h4 {
    font-size: 1.625rem;
  }
  .section-title {
    h3 {
      /*font-size: 6rem;*/
    }
    p {
      /* font-size: 2rem !important;*/
      line-height: normal;
    }
  }
  .title {
    /*font-size: 6rem;*/
    margin-bottom: 4rem;
  }
  /*.work_block h2 {
        font-size: 3rem;
    }*/
  /*.section_title h2 {
        font-size: 2.6rem;
    }*/
  .Know_us .media .media-body h6 {
    font-size: 1rem;
  }
  .Know_us .media .media-body p {
    font-size: 1.1rem;
    line-height: normal;
  }
  /*.blog-box h2 {
        font-size: 2rem;
    }*/
  .section_one .head-content {
    padding: 0;
    h2 {
      font-size: 3.125rem;
      margin-bottom: 1.5rem;
    }
    p {
      font-size: 1.688rem !important;
      width: 500px;
      margin-bottom: 1.5rem;
    }
  }
  .address-box {
    h2 {
      /*font-size: 3.3rem;*/
    }
    address span {
      line-height: 1.5;
      /*font-size: 2rem;*/
    }
  }
  .title-bar h3 {
    font-size: 3rem;
  }

  .tab-card {
    /*height: 370px;*/
    p {
      font-size: 1.575rem !important;
    }
  }

  .tab-card-content h3 {
    font-size: 2rem;
    margin-top: 0;
    line-height: normal;
  }
  /*  .section_two .tab-pane p {
        font-size: 2rem !important;
    }*/
  .section-six {
    height: auto;
    .blog-card {
      .blog-content {
        h3 {
          font-size: 1.5rem;
        }
        p {
          /*font-size: 2rem !important;*/
        }
      }
    }
  }
  /*.meeting-card .blue-card h2 {
        font-size: 3rem;
        line-height: 1.2;
    }*/
  /*.contact-block h3 {
        font-size: 2.5rem;
        line-height: 1.5;
    }*/
  ::placeholder {
    font-size: 1.5rem;
  }
  .form-control {
    font-size: 1.25rem;
    height: 70px !important;
  }
  .des-box h3 {
    font-size: 228.5%;
  }
  /*.footer-logo {
        width: 260px;
    }*/
  /*.main-footer .about-footer {
        font-size: 1.7rem;
    }*/
  /*.main-footer .foot-head h6 {
        font-size: 3rem;
    }*/
  /*.main-footer span {
        font-size: 1.9em;
    }*/
  /*.menu-title {
        font-size: 2rem;
    } */
  /*.foot-menu li a {
        font-size: 2rem;
    }  
    .main-footer ul li a img {
        width: 60px;
    }*/
  .section-three-one {
    /*.col-md-6 {
            width: 70%;
        }*/
    .client-card {
      span {
        /*font-size: 1.8rem;*/
      }
    }
    .our-partner {
      .media-icon {
        height: 106px;
        width: 106px;
        line-height: 106px;
        img {
          /*width: 120px;*/
        }
      }
      .media-body {
        h6 {
          font-size: 1.5rem;
          text-transform: none;
          line-height: normal;
        }
        p {
          font-size: 1.5rem !important;
          line-height: normal;
        }
      }
    }
  }
  .section-four {
    .service-card {
      .service-content {
        h3 {
          font-size: 2rem;
        }
        p,
        .link {
          /*font-size: 1.9rem !important;*/
        }
      }
    }
  }
  .cloud-drove-strip {
    span {
      &:first-child {
        /*font-size: 4rem;*/
      }
    }
    .year {
      /*font-size: 13rem;*/
    }
  }
  .section-five .content,
  .section-six .content {
    max-width: 100%;
    h3 {
      font-size: 2.1rem;
    }
    p {
      /*font-size: 2.2rem !important;*/
    }
  }
  .download-btn {
    span {
      /*font-size: 2.5rem;*/
      float: left;
      line-height: 1.1;
    }
    i {
      /*height: 70px;
            width: 70px;
            line-height: 80px;
            font-size: 2rem;*/
    }
  }
  .clients ul li a img {
    /* width: 300px;*/
  }
  .section-five .w-100 {
    width: auto !important;
  }
  .inner-casestudy{
    .study-card{
      height: auto!important;
    }

  }
}
@media screen and (min-width: 1600px) {
  .howwe-work .driection-dotted {
    left: 10%;
  }
  .container-fluid,
  .our-client-logo {
    max-width: 1290px;
  }
  .wedidtogether .container-fluid {
      max-width: 45%;
  }
  .time-map .clutch-widget {
    max-width: 63%;
    margin: 0 auto;
}
}
@media screen and (min-width: 1800px) {
  .howwe-work .driection-dotted {
    left: 13%;
  }
}
@media screen and (min-width: 1900px) {
  .howwe-work .driection-dotted {
    left: 15%;
  }
}

@media screen and (max-width: 1600px) {
  .hwoitwork_banner{
    .how-work-header{
      top: 34%;
    }
  }

  .container-fluid {
    max-width: 1170px;
  }
  .section_one {
    .container-fluid {
      margin-left: auto;
      margin-right: 0;
      max-width: 96%;
    }
    .head-content {
      padding: 0 4.5rem 0 2rem;
      h2 {
        font-size: 1.87rem;
      }
    }
  }

  .tab-card {
    .nav-tabs {
      .nav-item {
        .nav-link {
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
        }
      }
    }
  }
  .section-three {
    .casestudy-block {
      max-width: 94%;
      margin-left: auto;
    }
    .study-card::before {
      height: 190px;
      width: 190px;
    }
  }

  .career-banner,
  .career-top-text,
  .section-black-full,
  .our-team-main,
  .find-job,
  .job-details {
    .container-fluid {
      max-width: 86%;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .wedidtogether .container-fluid {
    max-width: 75%;
}
  .our-team-section .team-member-img .employees-quote {
    left: 40px;
  }
  .our-team-section.alternative-section .team-member-img .employees-quote {
    right: 40px;
  }

  .main-footer {
    .list-inline {
      .list-inline-item {
        margin-right: 0px !important;
        img {
          max-width: 60%;
        }
      }
    }
    .subscribe {
      .subscribe-btn {
        top: -8px;
      }
    }
  }

  .section-six {
    background-position: 0 0;
  }
  .btn-gradient-primary,
  .meeting-card .blue-card .btn-light {
    font-size: 1.238rem;
    padding: 0.58rem 2.5rem 0.58rem 2.5rem;
  }
  .meeting-card .blue-card p {
    margin-bottom: 3rem;
  }
  .gray-box {
    height: 220px;
    width: 300px;
  }
  .Know_us h2 {
    font-size: 3rem;
  }
  .about_des > div p {
    font-size: 1.25rem;
  }
  .blog-box h2 {
    font-size: 1.3rem;
    letter-spacing: 0.3rem;
  }
  .blog-box p {
    font-size: 1.3rem;
  }
  .blog-box h2::before {
    height: 6px;
  }
  .work_block h2,
  .meeting-card .blue-card h2 {
    font-size: 2rem;
  }
  .after-header {
    padding: 5rem 0;
    margin-bottom: 2.3rem;
  }
  .job-header {
    h2 {
      font-size: 2.1rem;
    }
  }

  .career_popup {
    max-width: 780px;
    .modal-content {
      .modal-body {
        padding: 1.288rem;
      }
      .scrollbar {
        max-height: 400px;
        overflow-y: auto;
        padding: 0 1.5rem;
      }
    }
    .career-popup-content {
      padding: 0 1.5rem;
    }
    h1 {
      font-size: 2rem;
    }
    .sub-heading {
      font-size: 1.2rem;
    }
  }
  .after-header {
    padding: 3rem 0;
    .after-header-content {
      h1,
      .right-text {
        font-size: 2rem;
      }
    }
  }
  .job-detail-middle {
    grid-template-columns: 58% 38%;
    grid-column-gap: 4%;

    .key-job-detail {
      ul.social-shareing li {
        margin-top: 0px;
        .social-icon {
          // padding: 0.5rem 1rem;
          // min-width: 5rem;
        }
      }
    }
  }
  .job-detail-middle .key-job-detail ul.social-shareing li .social-icon.more {
    top: 5px;
    position: relative;
}
}
@media only screen and (max-width: 1300px) {
  .career-banner {
    .carrer-content {
      h3 {
        font-size: 3rem;
        line-height: 2.8rem;
      }
      p {
      }
    }
  }
  .career-top-text {
    padding: 50px 0;
  }
  .our-team-section {
    padding: 4rem 0 6rem 0;
  }
  .career-top-text {
    .open-position-left {
      margin-bottom: 90px;
      h2 {
        font-size: 2.125rem;
      }
      p {
        font-size: 1.263rem;
      }
    }
  }
  .department-tabs {
    .bold-text {
      font-size: 1.15rem;
    }
    ul.nav {
      li.nav-item {
        a {
          font-size: 1.05rem;
        }
      }
    }
    .tab-content {
      .table-view {
        h3 {
          width: 54%;
          font-size: 1.15rem;
        }
      }
    }
  }
  .client-list,
  .top-card {
    .wrap {
      padding: 0 0.625rem;
    }
  }

  .our-team-section {
    .team-member {
      .team-member-text {
        h2 {
          font-size: 2rem;
        }
        p {
          font-size: 1.15rem;
        }
        .position-job {
          a {
            font-size: 1rem;
          }
        }
      }
    }
    .team-member-img {
      .employees-quote {
        max-width: 290px;
        padding: 1.438rem;
        bottom: -50px;
        height: auto;
        left: 20px;
        h4 {
          font-size: 1.2rem;
          margin-bottom: 1.625rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .find-job {
    padding: 5.813rem 0;
    .find-job-inner {
      padding: 4.813rem 0;
      h2 {
        font-size: 2.5rem;
      }
      .find-jobs {
        padding: 0.5rem 2.3rem;
        font-size: 1rem;
        h5 {
          font-size: 1rem;
        }
      }
    }
  }
  .our-team-section.alternative-section {
    .team-member-img {
      .employees-quote {
        right: 20px;
      }
    }
  }

  .section-black-full {
    padding: 50px 0;
    h3 {
      font-size: 2.113rem;
      margin-bottom: 3.063rem;
    }
    .preks-benefits-section {
      img {
        margin-bottom: 4.188rem;
      }
      .content-text {
        margin-bottom: 40px;
        h4 {
          font-size: 1.5rem;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
  }
  .main-footer {
    .container-fluid {
      max-width: 94%;
    }
  }
}
@media only screen and (max-width: 1280px) {
 
  .section-three .study-card:before {
    width: 190px;
    height: 190px;
  }
  .meeting-card .blue-card {
    padding: 3rem 2rem;
  }

  .job-detail-middle {
    .key-job-detail {
      ul.social-shareing {
        display: block;
        li {
          display: inline-block;
          margin-bottom: 1rem;
        }
      }
    }
  }
  .job-detail-middle {
    .key-job-detail {
      ul.social-shareing li {
        .social-icon {
          // padding: 0.5rem 1rem;
          // min-width: 2rem;
        }
      }
    }
  }

  .our-process .process-detial h3,
  .our-process-section .section_title h2 {
    font-size: 1.52rem;
  }
  .our-process .process-detial p {
    font-size: 1.2rem;
  }
  .find-library .cloud-github .spacing-cloud {
    width: 34%;
  }
}
@media only screen and (max-width: 1110px) {
  .howwe-work .driection-dotted {
    display: none;
  }
  .section-four {
    padding: 3rem 0 4rem !important;
  }
  .tab-card .nav-tabs .nav-item .nav-link {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 1190px) {
  .section_one .head-content {
    padding: 0 2em;
  }
  .section-three-one {
    .col-md-6 {
      width: 60%;
    }
  }
  .section-five-one .cust-card .left-side h6 {
    max-width: 120px;
  }
}
@media only screen and (max-width: 1140px) {
  .section_two {
    .offset-md-1 {
      margin-left: 4%;
      width: 46%;
    }
  }
  .clients {
    ul {
      li {
        img {
          width: 170px;
        }
      }
    }
  }
  .tab-card {
    padding: 0.625rem;
  }
  .section-four .service-card .service-content {
    padding: 1.25rem 0.625rem;
  }
  .meeting-card {
    .col-md-4 {
      width: 37%;
    }
  }
  .des-box {
    padding: 0.625rem;
  }
}
@media only screen and (max-width: 1100px) {
  .time-map .time-map-section .time-map-box .map-box::after{
    right: -61px;
  }
  .time-map .time-map-section .time-map-box .map-box.map-box-right::after{
    left: -50px;
  }
  .what-we-offer.section-black-full h3 {
    text-align: left;
  }
  .what-offer .ecosystem,
  .what-offer .find-out {
    width: 50%;
  }
  .what-offer .ecosystem p,
  .find-library h2 {
    font-size: 2rem;
  }
  .f-52 {
    font-size: 2.1rem !important;
  }
  .inner-casestudy .casestudy-block .flickity-page-dots {
    bottom: -40px;
  }
  .container-fluid {
    padding: 0 0.625rem;
  }
  .section-seven .contact-box {
    padding: 2.5rem 2rem;
  }
  .section-four {
    padding: 3rem 0;
  }
  .section-three .study-card {
    padding: 0.9375rem;
  }
  .about_des {
    padding-top: 3rem;
    padding-right: 0rem;
  }
  .Know_us .position-relative {
    height: 250px;
  }
  .gray-box {
    height: 160px;
  }
  .Know_us h2 {
    bottom: 20px;
  }
  .section-five-one .cust-card .left-side h6 {
    max-width: 110px;
  }
  .career-top-text {
    .open-position-left {
      max-width: 100%;
      margin-bottom: 50px;
      text-align: center;
    }
  }
  .jobs-department {
    .department-tabs {
      display: block;
      ul.nav {
        display: block;
        margin-right: 0px;
        width: 100%;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #222;
        li.nav-item {
          display: inline-block;
          margin: 0 10px;
        }
      }
      .tab-content {
        margin-bottom: 50px;
        .tab-pane {
          .tab-card-content {
            .table-view {
              padding-left: 0px;
              padding-right: 0px;
              h3 {
                text-align: left;
              }
            }
          }
        }
      }
    }
    .bold-text {
      max-width: 100%;
      text-align: center;
    }
  }
  .section-black-full {
    h3 {
      display: block;
      text-align: center;
    }
    .preks-benefits-section {
      max-width: 100%;
    }
  }
  .job-header {
    h2 {
      font-size: 2rem;
    }
  }
  .job-apply-form {
    max-width: 100%;

    h3 {
      font-size: 1.5rem;
    }
    .apply-job-from {
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 1010px) {
  .tab-card .nav-tabs .nav-item .nav-link {
    font-size: 1rem;
    padding: 0.5rem 0.9rem;
  }
  .section-three {
    .study-card {
      margin: 0.5rem;
    }
  }
  /*.section-six {
        height: 520px;
    }*/
  .blog-box {
    padding: 1.25rem 1rem;
  }
  .know_us h2 {
    font-size: 2.2rem;
  }
  .address-box h2 {
    font-size: 1.8rem;
  }
  .des-box h3 {
    font-size: 1.1rem;
  }
  .section-seven {
    padding: 3rem 0;
  }
  .section-three-one {
    .row.gx-5 {
      padding: 0 0.9375rem;
      & > * {
        padding: 0 0.625rem;
      }
    }
  }
  .hwoitwork_banner .how-work-header {
    max-width: 50%;
    h3,
    p.header-pragraph {
      font-size: 1.2rem;
    }
  }
  .our-process.maintain {
    padding-bottom: 0;
  }
  .what-offer {
    flex-direction: column;
    .ecosystem,
    .find-out {
      width: 100%;
    }
    .ecosystem {
      margin-bottom: 2rem;
    }
  }
  .find-library .cloud-github .spacing-cloud {
    width: 20%;
  }
  .hwoitwork_banner{
    .how-work-header{
      top: 36%;
    }
  }
}
@media only screen and (max-width: 970px) {
  .section-three-one .client-card {
    min-height: initial;
  }
  .section-three-one .client-card .img-wrap {
    height: 140px;
    line-height: 120px;
  }
  .section-seven {
    padding: 3rem 0;
  }
  .section-three .study-card h3 {
    font-size: 1.4rem;
  }
  .section_one .head-content {
    padding: 0;
  }
  .section_two .offset-md-1 {
    margin-left: 2%;
    width: 48%;
  }
  .tab-card .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 0.8rem;
  }
  .section-four .service-card .service-content {
    h3 {
      font-size: 1.3rem;
    }
    p {
      font-size: 0.9rem;
    }
  }
  .client-list .wrap {
    width: 25%;
  }
  .section-five-one {
    .gx-5.row {
      .col-md-7 {
        width: 48%;
      }
      .col-md-5 {
        width: 52%;
      }
    }
  }
}
@media only screen and (max-width: 940px) {
  .main-header .container-fluid {
      max-width: 94%;
  }
  .main-header .navbar-nav li a{
    font-size: 1.1rem;
  }
  .time-map .time-map-section .time-map-box .map-box::after{
    right: -57px;
  }
  .time-map .time-map-section .time-map-box .map-box.map-box-right::after{
    left: -46px;
  }
  .getTo_know {
    .Know_us.col-md-4 {
      width: 40%;
    }
    .about_des.col-md-8 {
      width: 60%;
    }
  }
  .blog-box p {
    margin-bottom: 0.5rem;
  }
}
@media only screen and (max-width: 900px) {
  .meeting-card {
    .blue-card {
      padding: 1rem;
      height: 500px;
    }
    .col-md-4 {
      width: 40%;
    }
    .col-md-6 {
      width: 60%;
      margin-left: 0 !important;
    }
  }
  .about-banner .about-content {
    top: 25%;
  }
  .Know_us h2 {
    font-size: 2.7rem;
  }
  .about_des {
    .mb-4 {
      margin-bottom: 0.5rem !important;
    }
    > div p {
      font-size: 0.9rem;
    }
  }
  .tab-card .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 0.6rem;
    font-size: 0.9rem;
  }
  .section-six .content {
    h3 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1rem;
    }
  }
  .blog-content {
    p {
      margin-bottom: 0;
    }
  }
  .section-seven {
    .contact-box {
      padding: 1rem;
      h2 {
        font-size: 2.3rem;
        margin-bottom: 1.2rem;
      }
    }
    .contact-content h2 {
      font-size: 3.2rem;
    }
  }
  .mail_user span {
    word-break: break-word;
  }
  .section-six .blog-card .blog-content h3 br {
    display: none;
  }
  .caseStudy-slider,
  .services-slider {
    .flickity-slider {
      .col-md-4 {
        flex: 0 0 auto;
        width: 50%;
      }
    }
  }
  .section-five {
    .content h3 br {
      display: none;
    }
  }
  .section_two .tab-pane p {
    font-size: 1.2rem;
  }
  .section-three-one {
    .col-md-6 {
      width: 80%;
    }
    .client-card span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1;
      height: 33px;
    }
  }
  .section-black-full {
    .preks-benefits-section {
      .content-text {
        margin-bottom: 0;
        .col-6 {
          width: 100%;
          margin-bottom: 1.5rem;
          text-align: center;
        }
        h4 {
          margin-bottom: 1rem;
        }
        p {
          text-align: center;
        }
      }
    }
  }
  .position-job {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }

  .our-team-section {
    h3 {
      text-align: center;
      margin-bottom: 2rem;
    }
    .team-member-img {
      width: 100%;
    }

    .team-member {
      .team-member-text {
        width: 100%;
        text-align: center;
        p {
          margin-bottom: 3rem;
        }
      }
    }
  }
  .our-team-section.alternative-section {
    h3 {
      text-align: center;
    }
  }
  .find-job {
    padding: 2.813rem 0;
    .find-job-inner {
      padding: 2.813rem 0;
      max-width: 100%;
      h2 {
        font-size: 2rem;
        margin-bottom: 25px;
      }
    }
  }

  .career_popup {
    max-width: 560px;
  }
  .hwoitwork_banner{
    .how-work-header{
      top: 35%;
    }
  }
}

@media only screen and (max-width: 820px) {
  .main-header .container-fluid {
      max-width: 95%;
  }
  .know_us {
    h2 {
      font-size: 1.6rem;
      line-height: 1.1;
    }
  }
  .address-box h2 {
    font-size: 1.3rem;
  }
  .address-box address span {
    font-size: 1rem;
  }
  .des-box p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .gray-box {
    width: 270px;
  }
  .know_us ul li a {
    font-size: 2rem;
  }
  .about-banner .about-content h3 {
    font-size: 2.2rem;
  }
  .about-banner .about-content h5 {
    font-size: 2rem;
  }
  .main-footer {
    padding: 2rem 0 2rem;
  }
  .tab-card-content h3 {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }
  .section-five {
    .content {
      font-size: 1.5rem;
      p {
        font-size: 1rem;
      }
    }
  }
  .blog-slider {
    .flickity-slider {
      .col-md-6 {
        flex: 0 0 auto;
        width: 100%;
      }
    }
  }
  .job-detail-middle {
    display: block;
  }
}
@media only screen and (max-width: 800px) {
  .client-list .wrap {
    width: 33.3%;
  }
  .section-five-one .gx-5.row {
    .col-md-7,
    .col-md-5 {
      width: 100%;
    }
  }
  .after-header {
    .after-header-content {
      display: block;
      text-align: center;
    }
  }
  .job-detial-footer {
    padding: 2rem 0;
    h4 {
      font-size: 2rem;
    }
  }
  .time-map .clutch-widget{
    max-width: 80%;
  }
}
@media only screen and (max-width: 767px) {
  .getTo_know .Know_us.col-md-4,
  .getTo_know .about_des.col-md-8{
    width: 100%;
  }
  .time-map .time-map-section .time-map-box .mapBoxinner .time-date-mob{
    display: block;
  }
  .time-map .time-map-section .time-map-box .mapBoxinner .time-date{
    display: none;
  }
  .time-map .time-map-section .time-map-box::after,
  .time-map .time-map-section .time-map-box .map-box::after {
    content: normal;
  }
  .time-map .time-map-section .time-map-box .mapBoxinner{
    flex-direction: column;
  }
  .time-map .time-map-section .time-map-box .time-date{
    margin-right: auto;
    height: auto;
    order:1;
  }
  .time-map .time-map-section .time-map-box .time-date.time-date-left {
    margin-top: 0px;
    margin-left: auto;
    order:1;
}
.wedidtogether .wedidtogether-window .window-content .col-md-4{
  padding:20px 0
}
.wedidtogether .wedidtogether-window .window-content{
  flex-direction: column;
}
  .time-map .time-map-section .time-map-box .map-box{
    width: 100%;
    order: 2;
  }
  .time-map .time-map-section .time-map-box .time-date img{
    max-width: 100%;
    margin-bottom: 1rem;
  }
  .our-process,
  .our-process.audit_suggestions {
    background-size: 48%;
    padding-bottom: 6rem;
  }
  .section-three.inner-casestudy {
    margin-bottom: 60px;
  }
  .roboto-img,
  .find-library .cloud-github .spacing-cloud {
    display: none;
  }
  .find-library .cloud-github {
    top: 0;
  }
  .find-library {
    h2 {
      width: 100%;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  .our-process.optimize {
    background-size: 22%;
    padding-bottom: 5rem;
  }
  .our-process .row .img-box {
    width: 100%;
    text-align: center;
    order: 1;
    .w-100 {
      width: 60% !important;
    }
  }
  .our-process .row .process-detial {
    text-align: center;
    width: 100%;
    order: 2;
  }

  .ps-4-5 {
    padding-left: 0rem !important;
  }
  .section_one .container-fluid {
    margin-right: auto;
    max-width: 90%;
  }
  .section_one .head-content h2 {
    font-size: 1.25rem;
  }
  .section-title h3 {
    font-size: 1.45rem;
  }
  .blog-wrap {
    margin-bottom: 1rem;
    .row > * {
      margin-top: 0 !important;
    }
  }
  .section_title {
    margin-bottom: 2rem;
    padding: 0rem;
    margin-top: 4rem;
  }
  .meeting-card .offset-md-1 {
    margin-left: 0;
}
.meeting-card .col-md-4 {
  width: 100%;
}
.meeting-card .blue-card {
  padding: 1rem;
  height: auto;
}
.wedidtogether .wedidtogether-window .heading-bg .action-btn-box{
  width: 50px;
}
.wedidtogether .wedidtogether-window .heading-bg{
  font-size: 1.25rem;
}

  .main-footer {
    .subscribe {
      margin-bottom: 2rem;
      max-width: 100%;
    }
    .mb-5 {
      margin-bottom: 0.5rem !important;
    }
  }
  .contact-block h3 {
    font-size: 1.3rem;
  }
  .caseStudy-slider {
    .flickity-slider {
      .col-md-4 {
        width: 100%;
      }
    }
  }
  .tab-card-content,
  .tab-pane {
    text-align: center;
  }
  .section-three-one .client-card {
    min-height: initial;
  }
  .section-three .casestudy-block {
    margin-right: auto;
  }
  .title {
    font-size: 2rem;
  }
  .content h3 {
    font-size: 1.25rem;
  }
  .section_two {
    .mb-5 {
      margin-bottom: 1rem !important;
    }
    .tab-card {
      margin-bottom: 1.5rem;
      height: auto;
      .nav-tabs .nav-item .nav-link {
        padding: 0.5rem 1rem;
      }
    }
    .col-md-5.offset-md-1 {
      width: 100%;
      margin-left: 0;
    }
    .tab-content {
      .tab-pane {
        img {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .section-three {
    padding: 2rem 0 1rem;
  }
  .caseStudy-slider .flickity-page-dots {
    bottom: -40px;
  }
  .section-five {
    padding: 2rem 0;
    img {
      max-width: 400px;
      margin: 0 auto;
      display: block;
    }
    .content {
      max-width: 100%;
      text-align: center;
    }
    .clients ul li img {
      width: 140px;
    }
  }
  .section-six {
    height: auto;
    padding: 2rem 0;
    .blog-card {
      img {
        max-width: 100%;
      }
    }
    .content {
      max-width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
  .section-seven .contact-content h2 {
    margin-bottom: 1rem;
  }
  .tab-card .nav-tabs {
    position: initial;
  }
  .section-three-one {
    .our-partner {
      padding: 1rem 0.5rem;
    }
    .col-md-6 {
      width: 100%;
    }
    .client-card {
      margin-bottom: 1.25rem;
    }
  }
  .career-top-text {
    .jobs-department {
      h3 {
        text-align: center;
        margin-bottom: 2rem;
      }
    }
  }
  .our-team-section {
    .team-member-img {
      .employees-quote {
        max-width: 170px;
        left: 0;
      }
    }
  }
  .our-team-section.alternative-section {
    .team-member-img {
      .employees-quote {
        right: 0px;
      }
    }
  }
  .navbar-light .navbar-toggler {
    margin-left: auto;
  }
  .inner-casestudy .casestudy-block .study-card img{
    max-width: 62%;
    margin: 0 auto;
  }
  .inner-casestudy .flickity-enabled.is-draggable .flickity-viewport{
    height: 300px!important;
  }
}
@media only screen and (max-width: 750px) {
  .meeting-card {
    .blue-card {
      p {
        margin-bottom: 1rem;
      }
      .content {
        p {
          font-size: 1rem;
        }
        h2 {
          line-height: 1;
        }
      }
    }
  }
  .flickity-enabled.is-draggable .flickity-viewport {
    // height: 200px !important;
  }
  .client-slider {
    .wrap {
      width: 30%;
    }
    .cust-card .cust-image {
      /*height: 90px;
            line-height: 90px;*/
    }
  }
  .section-five-one {
    padding: 2rem 0;
  }
  .job-header {
    text-align: center;
    h2 {
      font-size: 1.5rem;
    }
  }
  .job-detail-middle {
    .job-description {
      .apply-now-action {
        text-align: center;
      }
    }
  }
  .job-apply-form {
    .apply-job-from {
      .form-group {
        display: block;
        margin-bottom: 1rem;
      }
    }
    .inline-errormsg {
      position: relative;
      top: 0px;
      left: 0;
    }
  }
  .dividor {
    margin: 3rem 0;
  }
  .job-detial-footer h4 {
    font-size: 1.5rem;
  }
  .hwoitwork_banner .how-work-header p.header-pragraph {
    display: none;
  }
}
@media only screen and (max-width: 700px) {
  .time-map .clutch-widget{
    max-width: 100%;
  }
  .about-banner .about-content {
    h3 {
      font-size: 1.9rem;
    }
    h5 {
      font-size: 1.7rem;
      letter-spacing: 1px;
    }
  }
  .know_us ul li a {
    font-size: 1.5rem;
  }
  .Know_us .position-relative {
    height: 180px;
  }
  .Know_us h2 {
    font-size: 2.2rem;
    bottom: 27px;
    margin-bottom: 0;
  }
  .gray-box {
    width: 230px;
    height: 120px;
  }
  .Know_us .media {
    padding: 0.6rem;
    img {
      margin-right: 1rem;
    }
    .media-body {
      h4 {
        font-size: 1rem;
      }
      h6 {
        font-size: 0.8rem;
      }
    }
  }
  .tab-card-content h3 br {
    display: none;
  }
  .cloud-drove-strip .year {
    font-size: 5rem;
    line-height: 1;
    margin-top: -10px;
    letter-spacing: -3px;
  }
  .contact-block .contact-us-form {
    max-width: 100%;
  }
  .find-library .cloud-github .cloudgit {
    margin-right: auto;
  }
  .find-library .cloud-github .btn-gradient-primary {
    margin-left: 0;
  }
  .find-library .cloud-github .git-img {
    margin-right: 0;
    img {
      max-width: 70%;
    }
  }
}
@media only screen and (max-width: 650px) {
  .getTo_know {
    .Know_us.col-md-4,
    .about_des.col-md-8 {
      width: 100%;
    }
    .about_des {
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .getIn_touch {
    .d-between {
      display: block !important;
    }
    .know_us,
    .address-box,
    .des-box {
      width: 100%;
    }
    .know_us {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin-bottom: 1rem;
      h2 {
        margin-bottom: 0;
        br {
          display: none;
        }
      }
    }
    .address-box {
      margin-bottom: 1rem;
      padding: 0;
    }
  }
  .about_des {
    .crop {
      &::first-letter {
        font-size: 5rem;
      }
    }
    .ps-6 {
      padding-left: 4rem !important;
    }
  }
  .blog-box h2 {
    margin-bottom: 1.5rem;
  }
  .meeting-card {
    padding: 0.625rem;
    .col-md-4,
    .col-md-6 {
      width: 100%;
    }
    .blue-card {
      height: auto;
      margin-bottom: 1rem;
      border-radius: 1.2rem;
    }
    .contact-block {
      h3 {
        font-size: 1.1rem;
        margin-bottom: 1rem !important;
      }
    }
  }
  .our_work {
    margin-bottom: 3rem;
    margin-top: 2rem;
    .eq-height [class*="col-"] {
      margin-top: 1.7rem;
    }
  }
  .work_block h2 {
    font-size: 1.25rem;
  }
  .section-five .clients ul li img {
    width: 100px;
  }
  .career-banner {
    .carrer-content {
      margin: -75px auto 0 auto;
      .header-pragraph {
        display: none;
      }
    }
  }

  .jobs-department {
    .bold-text {
    }
  }
  .howwe-work .getIn_touch .know_us h2 {
    text-align: center;
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .client-list .wrap {
    width: 50%;
  }
  .career-banner .carrer-content h3 {
    font-size: 2.3rem;
    line-height: 2.12rem;
  }
  .our-team-section {
    .team-member-img {
      .employees-quote {
        position: relative;
        max-width: 100%;
        left: auto;
        right: auto;
        bottom: auto;
        top: 20px;
        display: inline-block;
      }
    }
  }
  .our-team-section {
    padding: 3rem 0;
    h3 {
      margin-bottom: 1.2rem;
    }
    .team-member {
      .team-member-text {
        h2 {
          font-size: 1.62rem;
        }
      }
    }
    .team-member-img {
      .member-pic {
        img {
          min-height: initial;
        }
        .user-name {
          justify-content: center;
        }
      }
    }
  }
  .our-team-section.alternative-section {
    .team-member-img {
      .member-pic {
        .user-name {
          justify-content: center;
        }
      }
    }
  }

  .career_popup {
    max-width: 86%;
    .career-popup-content {
      padding: 0 0.5rem;
    }
    h1 {
      font-size: 1.5rem;
    }
    .sub-heading {
      font-size: 1rem;
    }
  }

  .find-library .cloud-github {
    flex-direction: column;
    text-align: center;
    .git-img {
      margin-bottom: 2rem;
    }
    .cloudgit {
      margin-left: auto;
      margin-bottom: 2rem;
    }
  }
  
}
@media only screen and (max-width: 575px) {
  .section_one {
    .col-md-5 {
      order: 2;
    }
    .head-content {
      text-align: center;
      margin-bottom: 1rem;
      h2 br {
        display: none;
      }
      p {
        width: 100%;
      }
    }
  }
  .section-four .service-card {
    margin: 0 0.3rem;
  }
  .section-seven .contact-box {
    .d-flex {
      justify-content: center !important;
    }
  }
  .client-slider {
    .wrap {
      width: 40%;
    }
  }
  .inner-casestudy .casestudy-block .study-card img {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (max-width: 566px) {
  .career_popup {
    margin: 1rem auto;
    max-width: 90%;
    .modal-content {
      .modal-body {
        padding: 0.8rem;
      }
      .scrollbar {
        padding: 0 0.8rem;
      }
    }
  }
  .what-offer .find-out {
    grid-template-columns: 100%;
  }
  .what-offer .find-out .find-box .find-out-btn {
    margin-top: 0;
  }
  .inner-casestudy .section-title {
    margin-bottom: 0;
  }
  .our-process,
  .our-process.audit_suggestions,
  .our-process.optimize {
    padding-bottom: 3rem;
  }
  .wedidtogether .container-fluid {
    max-width: 90%;
}
.wedidtogether .wedidtogether-window .window-content{
  padding:0 2rem;
}
}
@media only screen and (max-width: 460px) {
  .about-banner .about-content {
    top: 17%;
    h3 {
      font-size: 1.4rem;
    }
    h5 {
      font-size: 1.2rem;
    }
  }
  .know_us h2 {
    font-size: 1rem;
  }
  .section_title h2:before {
    width: 250px;
  }
  .services-slider .flickity-slider .col-md-4 {
    width: 100%;
  }
  .section-five .clients ul li img {
    width: 85px;
  }
  .section_two .tab-card .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 0.6rem;
  }
  .section-three-one {
    padding: 2rem 0;
    .our-partner {
      .media {
        display: block;
        text-align: center;
        .media-icon {
          margin: 0 auto 0.625rem !important;
        }
      }
    }
  }
  .border-gray {
    &:before {
      width: 280px;
    }
  }
}
@media only screen and (max-width: 400px) {
  .client-list .wrap,
  .top-card .wrap {
    width: 100%;
  }
  .section-five-one .cust-card {
    margin-bottom: 0.625rem;
  }
  .client-slider {
    .wrap {
      width: 90%;
    }
  }
}
@media only screen and (max-width: 380px) {
  .title {
    font-size: 1.5rem;
  }
  .section-five {
    .clients {
      ul {
        li {
          &:last-child {
            display: none;
          }
        }
      }
    }
    .content h3 {
      font-size: 1.4rem;
    }
  }
  .cloud-drove-strip span:first-child {
    font-size: 1.2rem;
    letter-spacing: 0px;
  }
  .section_two .tab-card .nav-tabs .nav-item .nav-link {
    padding: 0.5rem 0.43rem;
    font-size: 0.8rem;
  }
}
