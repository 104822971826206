/*===== Index Style ======*/
@font-face {
  font-family: "Glacial Indifference";
  src: url("/assets/fonts/GlacialIndifference-Bold.eot");
  src: url("/assets/fonts/GlacialIndifference-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/GlacialIndifference-Bold.woff2") format("woff2"),
    url("/assets/fonts/GlacialIndifference-Bold.woff") format("woff"),
    url("/assets/fonts/GlacialIndifference-Bold.ttf") format("truetype"),
    url("/assets/fonts/GlacialIndifference-Bold.svg#GlacialIndifference-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Glacial Indifference";
  src: url("/assets/fonts/GlacialIndifference-Regular.eot");
  src: url("/assets/fonts/GlacialIndifference-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("/assets/fonts/GlacialIndifference-Regular.woff2") format("woff2"),
    url("/assets/fonts/GlacialIndifference-Regular.woff") format("woff"),
    url("/assets/fonts/GlacialIndifference-Regular.ttf") format("truetype"),
    url("/assets/fonts/GlacialIndifference-Regular.svg#GlacialIndifference-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
  font-family: "Glacial Indifference";
}
* {
  scrollbar-width: thin;
  scrollbar-color: #eee;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 20px;
  border: 3px solid #eee;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
  font-size: 1.25rem;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
  font-size: 1.25rem;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
  font-size: 1.25rem;
}
/*.container-fluid {
    padding: 0 3.125rem;
}*/
.media {
  display: flex;
  align-items: flex-start;
  .media-body {
    flex: 1;
  }
}
.main-header {
  background: white;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.6);
  .container-fluid {
    max-width: 84%;
  }
  .navbar-nav {
    li {
      &.active {
        a {
          font-weight: bold;
        }
      }
      a {
        font-size: 1.25rem;
        color: #555;
        padding-left: 0.9rem !important;
        padding-right: 0.9rem !important;
        transition: all 0.7s ease 0s;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }
}
.main-wrapper {
  margin-top: 4.1rem;
}
.m_b_4 {
  margin-bottom: 4rem !important;
}
.pos-right {
  position: relative;
  text-align: center;
  // right: -120px;
}
.section_one {
  padding: 2rem 0 4rem 0;
  margin-bottom: 0.625rem;
  .head-content {
    padding: 0 4.5rem;
    h2 {
      font-weight: bold;
      line-height: 1.1;
      color: #555;
    }
    p {
      width: 340px;
      font-size: 1.2rem;
      margin-bottom: 1.5rem;
    }
  }
}
.d-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-gradient-primary {
  background-image: linear-gradient(to bottom, #538efa, #1362e1);
  padding: 0.78rem 3rem 0.78rem 3rem;
  color: white;
  text-decoration: none;
  font-size: 1.438rem;
  border-radius: 50px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7);
  display: inline-block;
  &:hover {
    color: white;
    background-image: linear-gradient(to bottom, #1362e1, #538efa);
  }
}
.tab-card {
  /*background-image: linear-gradient(to bottom, #2f2f2f, #020202);*/
  background: rgb(1, 1, 1);
  background: linear-gradient(
    0deg,
    rgba(1, 1, 1, 1) 0%,
    rgba(21, 21, 21, 1) 43%,
    rgba(56, 56, 56, 1) 100%
  );
  padding: 1.25rem;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.4);
  position: relative;
  height: 275px;
  p {
    color: rgba(white, 0.62);
  }
  .nav-tabs {
    border: 0;
    position: absolute;
    bottom: 15px;
    .nav-item {
      .nav-link {
        border: none;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.25rem;
        color: rgba(white, 0.62);
        transition: all 0.7s ease 0s;
        cursor: pointer;
        padding: 0.5rem 1.5rem;
        &:hover {
          color: #f2bb11;
          margin-top: -6px;
        }
        &.active {
          color: #f2bb11;
          background-color: transparent;
          margin-top: -6px;
        }
      }
    }
  }
}
.border-orange {
  position: relative;
  margin-bottom: 0.925rem;
  display: inline-block;
  &:before {
    content: "";
    background: linear-gradient(
      130deg,
      #dd9118,
      #e6aa14 41.07%,
      #efc510 76.05%
    );
    display: inline-block;
    width: 100px;
    height: 6px;
    border-radius: 30px;
    position: absolute;
  }
}
.tab-card-content {
  h3 {
    font-weight: bold;
    color: #555;
    margin-bottom: 2.3rem;
  }
  p {
    font-size: 1.278rem;
    line-height: normal;
  }
}
.section_two {
  margin-bottom: 4rem;
  .tab-pane {
    p {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 1.278rem;
    }
  }
  /*.mb-5 {
        margin-bottom: 3.313rem !important;
    }*/
}
.section-title {
  margin-bottom: 6rem;
  position: relative;
  &::before {
    content: "";
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #333333;
    position: absolute;
    bottom: -65px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  h3 {
    margin-bottom: 1.25rem;
    font-weight: bold;
    color: #606060;
    font-size: 2.4rem;
    margin-top: 0px;
  }
  p {
    font-weight: bold;
    color: #606060;
    font-size: 1.25rem;
  }
}
.section-three {
  padding: 4rem 0 5rem;
  .study-card {
    background: #2e2e30;
    padding: 1.875rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    position: relative;
    margin: 0 0.9375rem;
    &:before {
      width: 290px;
      height: 250px;
      display: inline-block;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .count {
      background-image: linear-gradient(to bottom, #8a8a8b, #111);
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: bold;
      font-size: 1.25rem;
      color: #d4cdcd;
      box-shadow: 0 0 10px #000;
      border-radius: 0.3rem;
    }
    .date {
      font-size: 1rem;
      font-weight: bold;
      color: white;
      letter-spacing: 1px;
      position: relative;
      &:before {
        content: "";
        background: white;
        width: 50px;
        height: 2px;
        display: inline-block;
        position: absolute;
        bottom: 21px;
      }
    }
    p {
      color: rgba(white, 0.7);
      margin-bottom: 1.4rem;
    }
    h3 {
      color: white;
      font-weight: bold;
      max-width: 200px;
      line-height: 1.4;
      margin-bottom: 3rem;
    }
    &.icon-1:before {
      content: "";
      background: url("/assets/images/case-1-icon.png");
    }
    &.icon-2:before {
      content: "";
      background: url("/assets/images/case-2-icon.png");
    }
    &.icon-3:before {
      content: "";
      background: url("/assets/images/red-capsules.png");
    }
    .country {
      color: white;
      font-weight: bold;
    }
  }
}
.border-gray {
  @extend .border-orange;
  margin-bottom: 1.5rem;
  &:before {
    background: #606060;
    width: 350px;
    height: 8px;
  }
}
.title {
  text-align: center;
  margin-bottom: 1.875rem;
  color: #606060;
  font-weight: bold;
  font-size: 2.225rem;
}
.section-four {
  padding: 4.375rem 0 6rem;
  background: linear-gradient(0deg, #ababab, #ccc 41.07%, #fff 76.05%);
  .service-card {
    position: relative;
    margin: 0 0.625rem;
    .service-content {
      position: absolute;
      bottom: 0;
      padding: 1.875rem;
      color: white;
      h3 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1.2rem;
        margin-bottom: 1.25rem;
      }
      .link {
        text-decoration: none;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
.services-slider {
  .flickity-page-dots {
    bottom: -45px;
    .dot {
      background: white;
    }
  }
}
.cloud-drove-strip {
  background-color: #434343;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.9);
  span:first-child {
    color: #bebebe;
    font-size: 1.25rem;
    letter-spacing: 2px;
  }
  .year {
    font-size: 7rem;
    line-height: 0.73;
    font-weight: bold;
    margin-top: 0;
    letter-spacing: -2px;
    color: #797979;
  }
}
.d-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.d-flex-start {
  @extend .d-center;
  justify-content: start;
}
.d-flex-arround {
  justify-content: space-around;
  @extend .d-center;
}
.section-five {
  padding: 4.375rem 0 2rem;
  .clients {
    margin-bottom: 4rem;
  }
}
.download-btn {
  text-decoration: none;
  color: white;
  background: #6e6e6e;
  border: none;
  border-radius: 0;
  padding: 0;
  display: inline-block;
  span {
    background: #6e6e6e;
    line-height: 1;
    display: inline-block;
    padding: 0.8rem 1.5rem;
    font-size: 1.2rem;
  }
  i {
    background: #e05c5c;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 1.2rem;
  }
  &:hover {
    color: white;
  }
}
.section-six {
  padding: 4.375rem 0;
  background: url("/assets/images/publication-banner.png") no-repeat 0 -120px;
  background-size: cover;
  /*height: 650px;*/
  .content {
    h3 {
      color: #212529 !important;
    }
  }
  .blog-card {
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    margin: 0.3rem 0.625rem;
    .blog-content {
      padding: 1.25rem;
      h3 {
        color: #555555;
        font-weight: bold;
        font-size: 1.2rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
}
.content {
  max-width: 390px;
  width: 100%;
  h3 {
    font-weight: bold;
    color: #606060;
    font-size: 1.9rem;
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 2.2rem;
  }
}
.section-seven {
  background: url("/assets/images/contact-banner.png") no-repeat center;
  background-attachment: fixed;
  padding: 8rem 0;
  background-size: cover;
  .contact-content {
    h2 {
      font-size: 4rem;
      line-height: 1;
      color: white;
      font-weight: bold;
      text-shadow: 0 4px 6px black;
    }
  }
  .contact-box {
    background: rgba(white, 0.4);
    padding: 2.5rem 4rem;
    h2 {
      margin-bottom: 1.8rem;
      color: white;
      font-weight: bold;
      font-size: 3rem;
    }
    .form-control {
      border-radius: 0;
      border: 0;
      height: 50px;
    }
  }
}
.form-group {
  margin-bottom: 1rem;
}
.submit-btn {
  background: rgb(15, 134, 250);
  background: linear-gradient(
    0deg,
    rgba(15, 134, 250, 1) 0%,
    rgba(87, 160, 230, 1) 100%
  );
  border: 0;
  padding: 1rem 5rem;
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.6);
  border-radius: 0.8rem;
}
/*===== Footer Style =======*/
.main-footer {
  background: #262626;
  padding: 5rem 0 4rem;
  color: white;
  .foot-head {
    margin-bottom: 2.5rem;
    h6 {
      font-size: 1.1rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  .about-footer {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 2rem;
    display: inline-block;
  }
  .subscribe {
    position: relative;
    max-width: 60%;
    input {
      background: transparent;
      border-bottom: 2px solid rgba(255, 255, 255, 0.6) !important;
      border: 0;
      border-radius: 0;
      height: 50px;
      text-align: center;
      color: white;
      &:focus {
        box-shadow: none;
      }
    }
    .subscribe-btn {
      position: absolute;
      right: 0px;
      top: 6px;
      background: transparent;
      border: 0;
      font-size: 2rem;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
.menu-title {
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 1.25rem;
  letter-spacing: 2px;
}
.foot-menu {
  margin-bottom: 0;
  li {
    margin-bottom: 0.9rem;
    a {
      text-decoration: none;
      color: white;
      font-size: 1rem;
      letter-spacing: 2px;
    }
  }
}
.mail_user {
  span {
    display: block;
    font-size: 1.1rem;
    line-height: 1.1;
  }
}
.caseStudy-slider {
  .flickity-page-dots {
    bottom: -75px;
  }
}
.flickity-page-dots .dot {
  width: 13px;
  height: 13px;
  margin: 0 3px;
  background: #929292;
  border-radius: 50%;
  box-shadow: 0 3px 4px rgba(146, 146, 146, 0.6);
  opacity: 1;
  transition: all 0.5s ease 0s;
}
.flickity-page-dots .dot.is-selected {
  width: 55px;
  border-radius: 30px;
}
/*====== About Page ======*/
.about-banner {
  position: relative;
  .about-content {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
    transform: rotate(-25deg);
    h5 {
      font-family: "Playball", cursive;
      text-shadow: 2px 6px rgba(0, 0, 0, 0.3);
      font-size: 3rem;
      letter-spacing: 4px;
    }
    .text-blue {
      color: #71b5ff;
    }
    h3 {
      text-transform: uppercase;
      font-size: 3rem;
      font-weight: 600;
      color: #d0d0d0;
      text-shadow: 2px 6px rgba(0, 0, 0, 0.3);
      line-height: 1.1;
    }
  }
}
.getIn_touch {
  background-color: #71b5ff;
  padding: 2rem 0;
}
.know_us {
  width: 23%;
  padding: 0 0.9375rem;
  flex: 0 0 auto;
  h2 {
    font-size: 3rem;
    color: white;
    font-weight: bold;
    line-height: 3.375rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }
  ul {
    margin-bottom: 0;
    li {
      margin: 0 0.3rem;
      a {
        font-size: 2.7rem;
        line-height: 0.8rem;
        display: inline-block;
        color: rgba(white, 0.8);
      }
    }
  }
}
.address-box {
  width: 37%;
  padding: 0 0.9375rem;
  flex: 0 0 auto;
  color: rgba(white, 0.9);
  h2 {
    margin-bottom: 0.9rem;
    font-weight: bold;
    font-size: 2.3rem;
  }
  address {
    margin-bottom: 0;
    span {
      line-height: 1.3;
      font-size: 1.2rem;
    }
  }
}
address {
  span {
    display: block;
  }
}
.des-box {
  background-color: rgba(white, 0.8);
  padding: 1.25rem 1.875rem;
  box-shadow: 0 5px 8px rgba(black, 0.7);
  h3 {
    font-size: 1.4rem;
    font-weight: bold;
  }
  p {
    margin-bottom: 0;
    font-size: 0.87rem;
  }
}
.title-bar {
  background-color: #2d2d2d;
  padding: 0.9375rem 0;
  h3 {
    margin-bottom: 0;
    color: white;
    text-transform: capitalize;
  }
}
.Know_us {
  h2 {
    font-size: 3.75rem;
    color: #656565;
    font-weight: bold;
    margin-bottom: 1.8rem;
    line-height: 1;
    position: absolute;
    bottom: 0px;
    left: 7px;
    letter-spacing: 1px;
  }
  .position-relative {
    height: 300px;
  }
  .media {
    background: white;
    box-shadow: 0 8px 20px rgba(black, 0.5);
    border-radius: 1.2rem;
    margin-bottom: 1.875rem;
    padding: 0.9375rem;
    img {
      margin-right: 1.4rem;
      border: 5px solid #cbcbcb;
    }
    .media-body {
      color: #656565;
      h4 {
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: 0.2rem;
      }
      h6 {
        font-size: 0.9rem;
        margin-bottom: 0.2rem;
        font-weight: bold;
      }
    }
  }
}
.company-review{
  max-width: 60%;
}
.time-map{
  background-color: #f2f2f2;
  padding: 3rem 0;
  overflow: hidden;
  &:after{
    clear: both;
    content: '';
    display: block;
  }
  .clutch-widget {
      max-width: 70%;
      margin: 0 auto;
      iframe{
        body{
          background-color: transparent!important;
        }
      }
  }
  .time-map-section{
    position: relative;
    display: block;
    .time-map-box{
      display: block;
      // grid-template-columns: 50% 50%;
      &:after {
        content: '';
        width: 10px;
        background: #ddd;
        position: absolute;
        left: 50%;
        top: 18%;
        height: 80%;
    }
    .mapBoxinner{
      display: flex;
      align-items:center ;
      .time-date-mob {
        display: none;
      }
    }
      .map-box {
          background: #fff;
          float: left;
          border-radius: 10px;
          margin-bottom: 2rem;
          padding: 0rem;
          box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.1);
          width: 46%;
          display: flex;
          align-items: center;
          position: relative;
          z-index: 5;
          &:after{
              content: '';
              width: 30px;
              height: 30px;
              background: #3d91ff;
              border-radius: 100%;
              border: 5px solid #fff;
              display: inline-block;
              position: absolute;
              right: -71px;
              z-index: 5;
          }
          &.map-box-right{
            float: right;
            margin-left: auto;
            &:after{
              left: -61px;
              right: auto;
            }
            .year {
              border-left: 0;
              border-right: 1px solid #ccc;
            }
          }
          .back-in-harman {
            color: #656565;
            padding: 20px;
            width: 78%;
            display: block;
            font-size: 1.2rem;
          }

          .year {
            border-left: 1px solid #ccc;
            height: 100%;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            padding: 20px;
            font-size: 2.2rem;
            font-weight: 700;
            color: #656565;
            min-height: 154px;
          }
      }
      .time-date {
        margin-left: auto;
        float: right;
        width: 50%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        height: 143px;
        z-index: 2;
        margin-right: -8px;
        position: relative;
        &.time-date-left{
          margin-left: 5px;
          margin-right: auto;
          justify-content: flex-end;
          margin-top: 55px;
        }  
        img{
            max-width: 80%;
          }
          
      }
    }
    
    h3.team-tilte{
      display: block;
      margin: 30px 0 50px;
      font-family: 'Poppins', sans-serif;    
      font-size: 1.5rem;    
      text-align: center;
      font-weight: normal;    
      font-stretch: normal;    
      font-style: normal;    
      line-height: normal;    
      letter-spacing: normal;    
      color: #656565;
      position: relative;
      &:before{
        content: '';
        height: 2px;
        background-color: #979797;
        width: 100%;
        float: left;
        max-width: 30%;
        position: absolute;
        left: 50%;
        margin-left: -15%;
        top: -15px;
      }
    }

  }
}

.wedidtogether {
  float: left;
  width: 100%;
  background: #fff;
  padding: 70px 0;
  .container-fluid{
    max-width: 45%;
  }
  .wedidtogether-window{
    background-color: #fff;
    border-radius: 31px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.34);
    .heading-bg{
      width: 100%;
      float: left ;
      background:url(../../images/windowheading_bg.jpg) repeat-x 0 0;
      height: 60px;
      line-height: 60px;
      color: #fff;
      font-size: 2rem;
      border-radius: 31px 31px 0 0;
      text-align: center;
      padding: 0 50px;
      .action-btn-box {
          float: left;
          width: 90px;
          text-align: left;
          margin-top: -4px;
          img{
            max-width: 70%;
          }
      }
    }
    .window-content {
        padding: 50px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        .col-md-4{
          padding:0 20px;
        }
        h3{
          font-size: 1.52rem;
          font-weight: 700;
          position: relative;
          width: 100%;
          text-align: center;
          line-height: normal;
          margin-bottom: 20px;
          &:after{
            content: '';
            height: 2px;
            width: 100%;
            margin-top: 20px;
            display: block;
            background-color: #000;
          }
         
        }
        p{
          font-size: 20px;
          font-style: italic;
          line-height: normal;
          color: #656565;
        }
    }
  }
}
.gray-box {
  width: 388px;
  height: 297px;
  background: #d8d8d8;
  position: relative;
}
.about_des {
  padding-top: 5rem;
  padding-right: 7rem;
  > div {
    p {
      margin-bottom: 0;
      font-size: 1.3rem;
      line-height: 1.4;
      color: #3e3e3e;
    }
  }
  .crop {
    &::first-letter {
      font-size: 10rem;
      font-weight: bold;
      color: rgba(black, 0.3);
      line-height: 0.8;
      margin: -0.2em 0.1em 1.6em 0;
      float: left;
      display: flex;
    }
  }
}
.ps-4-5 {
  padding-left: 2rem !important;
}
.ps-6 {
  padding-left: 5.7rem !important;
}
.our-client-logo {
  max-width: 90%;
  margin: 0 auto;
}
.blog-box {
  border: 6px solid black;
  /*box-shadow: 0 0 5px rgba(black, 0.6);*/
  box-shadow: 0px 0px 5px rgba(black, 0.6), rgba(black, 0.6) 0px 0px 5px inset;
  padding: 2rem 2.56rem;
  margin-bottom: 2.56rem;
  h2 {
    font-size: 1.6rem;
    letter-spacing: 0.7rem;
    font-weight: bold;
    color: #656565;
    margin-bottom: 2.5rem;
    position: relative;
    line-height: 1.3;
    text-transform: uppercase;
    &::before {
      content: " ";
      background-color: #363636;
      height: 8px;
      width: 100%;
      display: inline-block;
      position: absolute;
      bottom: -14px;
    }
  }
  p {
    line-height: normal;
    font-size: 1.6rem;
    margin-bottom: 3.93rem;
    color: #656565;
  }
  img {
    border-top: 6px solid #363636;
    padding-top: 1.5rem;
  }
}
.getTo_know,
.blog-wrap {
  margin-bottom: 4.6rem;
}
.work_block {
  background: #d8d8d8;
  padding: 1.875rem;
  + .work_block {
    margin-top: 1.875rem;
  }
  h2 {
    color: #474747;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 1.2;
    margin-bottom: 0;
    padding-left: 1rem;
  }
  .count {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(black, 0.5);
    font-size: 1.3rem;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    margin-bottom: 0.9rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.5);
  }
}
.eq-height {
  [class*="col-"] {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
.section_title {
  padding: 4rem 0;
  text-align: center;
  h2 {
    margin-bottom: 0;
    font-size: 1.8rem;
    color: #656565;
    position: relative;
    &:before {
      content: "";
      background: #656565;
      width: 350px;
      height: 2px;
      display: inline-block;
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}
.our_work {
  margin-bottom: 4rem;
}
.meeting-card {
  background: #ebebeb;
  padding: 2rem;
  border-radius: 1.3rem;
  margin-bottom: 3rem;
  .offset-md-1 {
    margin-left: 3.333%;
  }
  .blue-card {
    background: rgb(15, 134, 250);
    background: linear-gradient(
      0deg,
      rgba(15, 134, 250, 1) 0%,
      rgba(87, 160, 230, 1) 100%
    );
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.3) 5px 0px 65px inset;
    padding: 3rem 5rem;
    border-radius: 2rem;
    color: white;
    h2 {
      font-size: 2.125rem;
      font-weight: bold;
      margin-bottom: 1rem;
      /*line-height: 1.2;*/
    }
    p {
      font-size: 1.25rem;
    }
    .btn-light {
      @extend .btn-gradient-primary;
      background-image: linear-gradient(to bottom, #fff, #dfdfdf);
      color: black;
      border-radius: 0.6rem;
      &:hover {
        color: black;
      }
    }
  }
}
.contact-block {
  .contact-us-form {
    max-width: 60%;
  }
  h3 {
    font-size: 1.75rem;
    color: #545454;
    line-height: 1.6;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .form-group {
    margin-bottom: 1.25rem;
    .form-control {
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid rgba(black, 0.7);
      height: 50px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .flex-end {
    margin-top: 3rem;
    button {
      border: none;
      border-radius: 0.7rem;
      width: 170px;
    }
  }
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
/*======= Popup Style =======*/
.service_popup {
  max-width: 700px;
  .modal-content {
    background-image: linear-gradient(to top right, #262626, #404040);
    border: none;
    border-radius: 0;
    .modal-body {
      padding: 1.3rem;
      > h5 {
        color: rgba(white, 0.4);
        font-size: 1.1rem;
        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
      }
    }
    .img_box {
      background-color: white;
      padding: 0.625rem;
      box-shadow: inset 0 0 8px black;
      text-align: center;
      margin-bottom: 1rem;
    }
    .media-left {
      max-width: 150px;
      margin-right: 1.3rem;
      h5 {
        color: rgba(white, 0.4);
        font-size: 1.1rem;
        text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
        text-align: center;
        margin-bottom: 0;
      }
    }
    .media-body {
      background-color: #1a1a1a;
      padding: 1.25rem;
      p {
        color: rgba(white, 0.62);
        font-weight: 400;
        font-size: 1.278rem;
      }
    }
    .scrollbar {
      max-height: 400px;
      overflow-y: scroll;
      box-shadow: inset 0 0 8px black;
      &::-webkit-scrollbar-track {
        background: #000;
      }
      &* {
        scrollbar-color: #404040;
      }
      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #404040;
        border: none;
      }
    }
    button.btn-primary {
      font-size: 0.8rem;
      text-transform: uppercase;
      border-radius: 0;
      padding: 0.4rem 2.8rem;
      box-shadow: 0 0 10px rgba(black, 0.7);
      font-weight: 600;
      letter-spacing: 1px;
      background-image: linear-gradient(to bottom, #5690fb, #0e5fdf);
    }
  }
}
/*====== Toaster Style =======*/
.toaster-box {
  position: relative;
  margin-bottom: 1rem;
  a {
    background: red;
    width: 25px;
    height: 25px;
    text-align: center;
    text-decoration: none;
    line-height: 25px;
    display: inline-block;
    border-radius: 100%;
    color: white;
  }
  .toast {
    width: 100%;
    background-color: rgba(210, 244, 207, 0.8);
    border-radius: 0;
    .toast-body {
      font-weight: bold;
      color: #198754;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.error-message {
  color: #bdaeae;
}

/*===== New Css =====*/
.section-three-one {
  background-image: linear-gradient(to right, #f3f3f3, #d2d2d2);
  padding: 4rem 0;
  .our-partner {
    background-image: linear-gradient(to right, #5fb4f8, #0a7ffe);
    padding: 2rem 2.5rem;
    border-radius: 0.5rem;
    box-shadow: 4px 5px 15px rgba(black, 0.6);
    .me-3 {
      margin-right: 1.5rem !important;
    }
    .media-icon {
      background: rgba(255, 255, 255, 0.3);
      height: 100px;
      width: 100px;
      line-height: 100px;
      text-align: center;
      border-radius: 100%;
      img {
        /*width: 80px;*/
        height: auto;
        padding: 0.3rem;
      }
    }
    .media-body {
      color: white;
      h6 {
        font-weight: bold;
        font-size: 1.4rem;
      }
      p {
        font-size: 1.125rem;
      }
    }
  }
  .client-card {
    background: #fff5f5;
    text-align: center;
    padding: 1.25rem;
    border-radius: 0.3rem;
    box-shadow: 0 0 20px rgba(black, 0.1);
    transition: all 0.3s;
    min-height: 325px;
    &:hover {
      transform: scale(1.03) translateY(-4px);
      box-shadow: 5.142px 6.128px 40px 3px rgba(black, 0.2%);
    }
    .img-wrap {
      width: 100%;
      height: 210px;
      margin: 0 auto;
      line-height: 210px;
      img {
        width: 90px;
      }
    }
    span {
      text-transform: capitalize;
      font-weight: normal;
      color: rgba(black, 0.7);
    }
  }
}
.section-five-one {
  background-color: #f0f0f0;
  padding: 4rem 0;
  .cust-card {
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(black, 0.2);
    transition: all 0.3s;
    // &:hover {
    //     transform: scale(1.03) translateY(-4px);
    //     box-shadow: 5.142px 6.128px 40px 3px rgba(black, 0.2%);
    // }
    .cust-image {
      width: 100%;
      height: 126px;
      line-height: 126px;
      text-align: center;
    }
    .left-side {
      h6 {
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: bold;
        max-width: 140px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .more-icon {
      img {
        opacity: 0.5;
      }
    }
  }
}
.top-card .wrap {
  width: 50%;
  display: inline-block;
  padding: 0 1.25rem;
}
.wrap {
  width: 20%;
  padding: 0.5rem 0.625rem 0;
  margin-bottom: 0.5rem;
}

/*---career Page css start ---*/
.career-banner {
  position: relative;
  .container-fluid {
    max-width: 1290px;
  }
  .carrer-content {
    position: absolute;
    top: 50%;
    bottom: auto;
    left: auto;
    z-index: 1;
    margin: -185px auto 0 auto;
    max-width: 420px;
    right: auto;
    h3 {
      color: #fff;
      font-size: 4rem;
      text-transform: uppercase;
      font-weight: 700;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.69), 0 2px 4px rgba(0, 0, 0, 0.5);
      line-height: 3.5rem;
      margin-bottom: 1.5rem;
    }
    p {
      color: #fff;
      font-size: 1.875rem;
      line-height: 1.3;
      font-weight: 700;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
}
.our-team-main {
  padding: 0px !important;
}
.career-top-text,
.section-black-full,
.our-team-main,
.find-job {
  padding: 110px 0;
  .container-fluid {
    max-width: 1290px;
  }
  .open-position-left {
    max-width: 42%;
    float: left;
    margin-bottom: 150px;
    h2 {
      font-size: 2.813rem;
      font-weight: 700;
      color: #131415;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.563rem;
      line-height: normal;
      color: #131415;
      font-weight: 300;
    }
  }
  .clear-both {
    display: block;
    clear: both;
  }
  .jobs-department {
    float: left;
    width: 100%;

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(19, 20, 21, 0.8);
      text-transform: uppercase;
      margin-bottom: 2.5rem;
    }
  }
}

.department-tabs {
  display: flex;
  ul.nav {
    display: block;
    clear: both;
    width: 28%;
    margin-right: 2rem;
    border-bottom: 0px;
    li.nav-item {
      display: block;
      a {
        font-weight: 400;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(19, 20, 21, 0.5);
        font-size: 1.25rem;
        border: 0px;
        cursor: pointer;
        padding: 0.638rem 0;
      }
      :hover {
        border: 0px;
        font-weight: 700;
      }
      .active {
        border: 0px;
        font-weight: 700;
      }
    }
  }
  .tab-content {
    width: 100%;
    margin-bottom: 170px;
    .table-view {
      display: flex;
      padding: 1.5rem 3rem 1.1rem 1.1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
      h3 {
        margin-bottom: 0px;
        text-transform: capitalize;
        font-size: 1.25rem;
        letter-spacing: 0.05rem;
        width: 33%;
      }
      .count-bold {
        font-weight: 700;
      }
      a {
        font-size: 15px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(19, 20, 21, 0.5);
        text-decoration: none;
        margin-left: auto;
      }
    }
  }
}

.bold-text {
  max-width: 46%;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
}
.section-black-full {
  background: #000;
  padding: 93px 0;

  h3 {
    display: block;
    color: #fff;
    font-size: 2.813rem;
    line-height: normal;
    margin-bottom: 4.063rem;
    font-weight: 700;
  }

  .preks-benefits-section {
    max-width: 80%;
    float: right;
    img {
      margin-bottom: 6.188rem;
    }
    .content-text {
      width: 100%;
      float: left;
      margin-bottom: 88px;
      color: #fff;
      .col-6 {
        float: left;
        width: 46%;
      }
      .col-6.last {
        float: right;
      }
      h4 {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.25rem;
        text-align: justify;
        line-height: normal;
      }
    }
  }
  .container-fluid.d-between {
    display: block;
  }
  :after {
    content: "";
    display: block;
    clear: both;
  }
}

.our-team-section {
  background: #f9f9f9;
  padding: 8rem 0;
  float: left;
  width: 100%;
  h3 {
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #868690;
  }

  .team-member-text {
    width: 35%;
    float: left;
    h2 {
      color: #000;
      font-size: 2.315rem;
      line-height: normal;
      font-weight: 700;
      margin-bottom: 2.375rem;
      span {
        color: #5bbcff;
        display: block;
      }
    }
    p {
      font-size: 1.25rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #000000;
      margin-bottom: 5rem;
    }
    .position-job {
      .position-box {
        font-weight: 700;
        color: #000;
        font-size: 1.2rem;
        text-decoration: none;
        padding: 1rem 1.5rem;
        border-radius: 10px;
        border: solid 1px #646464;
      }
    }
  }
  .team-member-img {
    width: 65%;
    float: right;
    .member-pic {
      max-width: 543px;
      float: right;
      overflow: hidden;
      img {
        /*border: 10px solid #fff;*/
        max-width: 100%;
        height: auto;
        min-height: 500px;
      }
      .user-name {
        padding: 0.1em 0.5em 0.1em 0.5em;
        text-align: right;
        align-content: flex-end;
        justify-content: flex-end;
        align-items: flex-end;
        display: flex;
      }
    }

    .employees-quote {
      background: #fff;
      position: absolute;
      left: 85px;
      padding: 2.438rem;
      max-width: 341px;
      z-index: 1;
      height: 389px;
      bottom: -39px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      h4 {
        font-size: 1.8rem;
        color: #000;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 2.625rem;
      }
      p {
        font-size: 1.25rem;
        line-height: 1.5;
        color: #000;
      }
    }
  }
}
.our-team-section.alternative-section {
  background-color: #fff;
  h3 {
    text-align: right;
  }
  .team-member-text {
    float: right;
    text-align: right;
  }
  .team-member-img {
    .member-pic {
      float: left;
      .user-name {
        justify-content: flex-start;
      }
    }
    .employees-quote {
      left: auto;
      right: 100px;
    }
  }
}
.relative {
  position: relative;
}
.main-footer:after,
.container-fluid:after {
  content: "";
  display: block;
  clear: both;
}
.clearboth {
  clear: both;
  display: block;
}

.find-job {
  padding: 7.813rem 0;
  display: block;
  clear: both;
  text-align: center;
  h5 {
    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #868690;
  }
  .find-job-inner {
    padding: 7.813rem 0;
    border-top: 1px solid #606060;
    border-bottom: 1px solid #606060;
    max-width: 70%;
    text-align: center;
    justify-content: center;
    margin: 0 auto;
    h2 {
      font-size: 3rem;
      font-weight: 300;
      line-height: normal;
      color: #000;
      margin-bottom: 63px;
      span {
        font-weight: 700;
      }
    }
    .find-jobs {
      padding: 1rem 3rem;
      background-color: #000000;
      color: #fff;
      font-size: 1.25rem;
      display: inline-block;
      text-decoration: none;
    }
  }
}

.after-header {
  margin-bottom: 3rem;
  padding: 8rem 0;
  background-image: linear-gradient(
    100deg,
    rgba(78, 78, 78, 0.3) -2%,
    rgba(18, 18, 18, 0.3) 100%
  );

  .after-header-content {
    display: flex;
    justify-content: space-between;

    h1,
    .right-text {
      font-size: 2.5rem;
      line-height: normal;
      color: #fff;
      strong {
        font-weight: 700;
      }
    }
  }
}
.job-header {
  margin-bottom: 3.5rem;
  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #414141;
    .separator {
      display: block;
    }
  }
}
.breadcamb {
  display: block;
  list-style-type: none;
  padding-left: 0;
  li {
    display: inline-block;
    list-style-type: none;
    color: #a0a0a0;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    padding-right: 5px;

    a {
      color: #a0a0a0;
      text-decoration: none;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  :hover {
    a {
      color: #000;
    }
  }
}
.apply-now-action {
  display: block;
  margin: 2rem 0;
}
.apply-now-btn {
  padding: 0.638rem 2.5rem;
  cursor: pointer;
  background-color: #4889ff;
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  border: 0px;
  border-radius: 0px;
  span {
    margin-left: 1rem;
    font-size: 1.3rem;
    position: relative;
    top: 0px;
  }
}
.apply-now-btn:hover {
  background-color: #333;
  color: #fff;
}
.apply-now-btn.btn-secondary:focus,
.apply-now-btn.btn-secondary:focus-visible {
  box-shadow: 0 0 0 transparent;
  outline: 0;
  background-color: #4889ff;
}

.job-detail-middle {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-column-gap: 30px;

  .job-description {
    p {
      text-align: justify;
      strong {
        display: block;
        margin-top: 1.2rem;
      }
    }
  }
  .sidebar-img {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 4px 3px 4px 0 rgba(0, 0, 0, 0.5);
    border: solid 1px #979797;
    margin-bottom: 2rem;
  }
  .key-job-detail {
    h3 {
      font-weight: 300;
      color: #212121;
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      border-bottom: 1px solid #979797;
      li {
        margin-bottom: 1rem;
        font-size: 1rem;
        color: rgba(33, 33, 33, 0.54);
        font-weight: 700;
        span {
          font-weight: 300;
        }
      }
    }
    ul.social-shareing {
      display: flex;
      border-bottom: 0;
      margin-top: 1.5rem;
      label {
        line-height: 42px;
      }
      li {
        margin: 3px 0rem 0 1rem;
        width: 40px;
        .social-icon {
          padding:0;
          text-align: center;
          background: #5a5a5a;
          border-radius: 0px;
          color: #fff;
          display: block;
          width: 40px;
          line-height: 36px;
          border: 0;
          display: inline-block;
        }
        .fb {
          background: #6688ce;
        }
        .twitter {
          background: #29c5f5;
        }
        .linkedin {
          background: #3a9bda;
        }

        :hover {
          background: #333;
        }
      }
    }
  }
}

.job-detial-footer {
  background-image: linear-gradient(
    95deg,
    rgba(78, 78, 78, 0.3) -2%,
    rgba(18, 18, 18, 0.3) 100%
  );
  padding: 3rem 0;
  color: #fff;
  margin-top: 2rem;
  h4 {
    font-size: 2.3rem;
    color: #fff;
    font-weight: 300;
  }
}
.job-footer-dark {
  background-image: linear-gradient(95deg, #4e4e4e -2%, #000 100%);
}
.career_popup {
  max-width: 912px;
  .modal-content {
    border-radius: 0px;

    .modal-body {
      padding: 2.188rem;
    }
    .scrollbar {
      max-height: 500px;
      overflow-y: auto;
      padding: 0 1.5rem;
    }
  }
  .career-popup-content {
    padding: 0 2.5rem;
  }
  h1 {
    font-size: 2.5rem;
    text-transform: 300;
    color: #565a5c;
    line-height: 1.5;
    margin-bottom: 0px;
  }
  .sub-heading {
    font-size: 20px;
    font-weight: 300;
    color: #565a5c;
    margin-bottom: 2rem;
  }
  p {
    font-size: 15px;
    color: #575757;
    text-align: justify;
  }

  .agree-btn-full {
    padding: 0 2.35rem;
    text-align: center;
    margin-top: 1.5rem;
    p {
      text-align: center;
    }
  }
}
.agree-btn {
  background-color: #000;
  color: #fff;
  padding: 0.925rem;
  display: block;
  text-align: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.agree-btn:hover {
  color: #fff;
  background-color: #4889ff;
}
.agree-btn.disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.none {
  display: none;
}

.job-apply-form {
  h3 {
    font-size: 2rem;
    color: #545454;
    line-height: normal;
    margin-bottom: 2rem;
  }
}
.job-apply-form {
  max-width: 90%;
  .apply-job-from {
    display: block;
    max-width: 81%;
    .form-group {
      display: flex;
      margin-bottom: 2rem;
      &.phone-no{
        .PhoneInput{
          height: 48px;
          border-radius: 10px;
          font-size: 1rem;
          border:1px solid #979797;
          width: 100%;
          padding: .375rem .75rem;
          input[type="tel"]{
            border:0px;
            &:focus{
              outline: none;
            }
          }
        }
      }
      label {
        width: 46%;
        line-height: 40px;
        sup {
          color: #fc1717;
          top: 0px;
        }
      }
      .form-control {
        height: 48px !important;
        border-radius: 10px;
        font-size: 1rem;
        border-color: #979797;
      }

      textarea::placeholder,
      input::placeholder {
        font-size: 1rem;
        color: #b2b2b2;
      }
      .textarea {
        border-radius: 10px;
        font-size: 1rem;
        padding: 0.375rem 0.75rem;
        width: 100%;
        border: 1px solid #979797;
        resize: none;
        min-height: 150px;
      }
      .fileuploader {
        width: 100%;
        margin: 5px 0;
      }
    }
    h6 {
      margin: 2.5rem 0;
      font-size: 1rem;
      text-transform: uppercase;
      color: #545454;
      font-weight: bold;
    }
  }
  .inline-errormsg {
    position: relative;
    top: -24px;
    left: 32%;
  }
}

.dividor {
  width: 100%;
  display: block;
  height: 1px;
  background: #979797;
  margin: 6rem 0;
}
.mb-6 {
  margin-bottom: 6rem;
}

.sucess-popup {
  max-width: 620px;
  text-align: center;

  .oval {
    overflow: hidden;
    border-radius: 50%;
    background-image: linear-gradient(to bottom, #9fc185, #0f8a72 99%);
    width: 110px;
    height: 110px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .oval-inner {
      background-color: #fff;
      border-image-slice: 1;
      width: 90px;
      height: 90px;
      border-radius: 60px;
    }
  }
  .modal-content {
    .modal-body {
      padding: 3rem 1rem;
    }
  }
  .career-popup-content {
    padding: 0;
    text-align: center;
    p {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
}

/*----How we work Page css Start here----*/
.hwoitwork_banner {
  position: relative;
  .how-work-header {
    position: absolute;
    left: 38%;
    top: 36%;
    max-width: 654px;
    transform: translate(-50%, -50%);
    h3 {
      color: #fff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 1.875rem;
      margin-bottom: 1.25rem;
      font-weight: bold;
    }
    p.header-pragraph {
      color: #fff;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
      font-size: 1.875rem;
      font-weight: normal;
    }
  }
}

.howwe-work {
  position: relative;
  .know_us {
    width: 30%;
  }
  .driection-dotted {
    background: url(/assets/images/direction_1.png) no-repeat 0 0;
    height: 100%;
    display: block;
    position: absolute;
    left: 6%;
    top: 80px;
    width: 50px;
  }
}
.our-process-section {
  display: flex;
  width: 100%;
  padding: 40px 0;
  .section_title {
    h2 {
      font-size: 3.125rem;
      font-weight: bold;
    }
  }
}
.our-process.audit_suggestions {
  background: url(/assets/images/direction_3.png) no-repeat center bottom;
}

.our-process.optimize {
  background: url(/assets/images/direction_4.png) no-repeat 68% bottom;
}
.our-process.maintain {
  background: none;
}
.our-process.maintain {
  .process-detial {
    margin: 0 auto;
    text-align: center;
  }
}
.our-process {
  display: flex;
  padding-bottom: 7rem;
  background: url(/assets/images/direction_2.png) no-repeat center bottom;

  .row {
    display: flex;
    align-items: center;
    .img-box {
      width: 50%;
      order: 0;
    }
    .process-detial {
      width: 50%;
      order: 0;
    }
  }
  .process-detial {
    // padding-left: 5%;
    h3 {
      font-size: 2.5rem;
      font-weight: bold;
    }
    p {
      color: #494949;
      font-size: 1.35rem;
      line-height: normal;
    }
  }
}

.our-process:nth-child(2n) {
  .img-box {
    order: 1;
  }
  .process-detial {
    order: 2;
  }
}
.f-52 {
  font-size: 3rem !important;
}
.what-offer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .ecosystem {
    width: 55%;
    align-items: center;
    p {
      font-size: 2.5rem;
      color: #fff;
      margin-bottom: 0;
      line-height: normal;
    }
  }
  .find-out {
    width: 45%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 30px;
    .find-box {
      padding: 30px;
      border-radius: 2px;
      h5 {
        font-size: 1.5rem;
        line-height: normal;
        margin-bottom: 2rem;
        color: #606060;
        font-weight: bold;
        position: relative;
      }
      h5:before {
        content: "";
        height: 4px;
        max-width: 60%;
        background-color: #525252;
        display: block;
        margin-bottom: 10px;
      }
      p {
        color: #626262;
        font-size: 1.1rem;
      }
      .find-out-btn {
        font-size: 1.5rem;
        max-width: 80%;
        display: block;
        color: #fff;
        text-decoration: navajowhite;
        line-height: normal;
        font-weight: 700;
        margin-top: 65px;
      }
      .find-out-btn:after {
        content: "";
        height: 4px;
        width: 83%;
        background-color: #fff;
        display: block;
        margin-top: 12px;
      }
    }
  }
}
.inner-casestudy {
  .section-title {
    margin-bottom: 3rem;
    p {
      font-weight: normal;
    }
  }
  .section-title:before {
    content: normal;
  }
  .border-gray {
    display: none;
  }
  .casestudy-block {
    .study-card {
      background: none;
      box-shadow: none;
      padding: 0;
      img {
        max-width: 100%;
        display: block;
      }
    }
    .study-card:before {
      content: normal;
    }
    .flickity-page-dots {
      bottom: -40px;
      .dot {
        box-shadow: none;
        border: solid 1px #979797;
        background-color: #d8d8d8;
      }
    }
  }
}

.find-library {
  background: #f2f2f2;
  padding: 4rem 0;
  h2 {
    font-size: 2.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #646464;
    float: left;
  }
  .roboto-img {
    float: right;
  }

  .cloud-github {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #fff;
    border-radius: 23px;
    padding: 20px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.33);
    border: solid 1px #d1d1d1;
    align-items: center;
    position: relative;
    top: -100px;

    .spacing-cloud {
      width: 43%;
      margin-left: auto;
      margin-right: auto;
      height: 1px;
      background: #979797;
    }
    .git-img {
      margin-right: 30px;
    }
    .cloudgit {
      font-size: 1.2rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #656565;
      margin-right: 30px;
    }
    .btn-gradient-primary {
      margin-left: 30px;
    }
  }
}
