.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast-body {
  font-family: Poppins;
  padding: 0px;
}

.Toastify__toast--success {
  background: #33b5e5 !important;
  color: #fff !important;
}
.Toastify__toast--error {
  background: #dc3545 !important;
  color: #fff !important;
}
.Toastify__close-button {
  color: #fff !important;
}
.Toastify__close-button > svg {
  color: #fff !important;
}
.widgets_wrapper.gw_wrapper {
  max-width: 100% !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
